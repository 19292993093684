// src/AboutUs.js
import React from 'react';
import styled from 'styled-components';
import teamImage from './images/background.png'; // Remplacez par l'image de votre équipe

const AboutUsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TeamImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
`;

const TextWrapper = styled.div`
  width: 50%;
  padding-left: 20px;
  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    padding-top: 20px;
  }
`;

const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Text = styled.p`
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.5;
`;

const AboutUs = () => {
  return (
    <AboutUsWrapper>
      <ImageWrapper>
        <TeamImage src={teamImage} alt="Team" />
      </ImageWrapper>
      <TextWrapper>
        <Title>About Us</Title>
        <Text>
          Welcome to our world of gaming! Founded in 2023, our team at OTP KDA
          is dedicated to creating unique and immersive gaming experiences for
          players around the globe. As a new player in the gaming industry, we
          are passionate about pushing the boundaries of technology, storytelling,
          and design to create memorable adventures for our audience.
        </Text>
        <Text>
          Our talented and diverse team is united by a shared love for gaming and
          a commitment to innovation. We believe that games have the power to
          bring people together and inspire them to explore new worlds and
          ideas. Our mission is to build a thriving community of gamers who
          share our excitement for the future of interactive entertainment.
        </Text>
        <Text>
          Join us on our journey as we continue to grow and redefine the gaming
          landscape. We can't wait to share our passion with you!
        </Text>
      </TextWrapper>
    </AboutUsWrapper>
  );
};

export default AboutUs;
