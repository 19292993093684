import React, { useState } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './images/logo.png';
import AboutUs from './AboutUs';
import HomePage from './HomePage';
import Careers from './Careers';
import Contact from './Contact';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  background-color: #000;
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
`;

const MenuItem = styled(Link)`
  color: #fff;
  font-size: 18px;
  margin: 0 15px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
`;

const JoinButton = styled(Link)`
  background-color: red;
  color: #fff;
  font-size: 18px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
`;

const LogoImage = styled.img`
  height: 90px; // Vous pouvez ajuster la taille de l'image ici
  margin-right: 10px; // Ajoutez une marge entre le logo et le texte
`;


const Menu = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`;

const HamburgerMenu = styled.div`
  display: none;
  flex-direction: column;
  gap: 4px;
  width: 30px;
  height: 20px;
  justify-content: space-between;
  cursor: pointer;

  div {
    height: 4px;
    background-color: #fff;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const MobileMenu = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #000;
    padding: 10px;
    border-radius: 5px;
  }
`;


const PageWrapper = styled.div`
  padding: 20px;
`;

function App() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Router>
      <HeaderWrapper>
        <Logo to="/">
        <LogoImage src={logo} alt="Logo" />
          OTP KDA
        </Logo>
        <Menu>
          <MenuItem to="/about">About</MenuItem>
          <MenuItem to="/careers">Careers</MenuItem>
          <MenuItem to="/contact">Contact us</MenuItem>
        </Menu>
        <JoinButton to="/join">Join us</JoinButton>
        <HamburgerMenu onClick={toggleMobileMenu}>
          <div />
          <div />
          <div />
        </HamburgerMenu>
      </HeaderWrapper>
      <MobileMenu isOpen={isMobileMenuOpen}>
        <MenuItem onClick={toggleMobileMenu} to="/about">
          About
        </MenuItem>
        <MenuItem onClick={toggleMobileMenu} to="/careers">
          Careers
        </MenuItem>
        <MenuItem onClick={toggleMobileMenu} to="/contact">
          Products
        </MenuItem>
      </MobileMenu>
      <PageWrapper>
        <Routes>
          <Route path="/" element={<HomePage />} exact />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          {/* Ajoutez d'autres routes ici */}
        </Routes>
      </PageWrapper>
    </Router>
  );
}

export default App;
