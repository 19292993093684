import React from 'react';
import styled from 'styled-components';
import homeVideo from './images/video.mp4';

const HomePageWrapper = styled.div`
  height: 80vh;
  width: 100%;
  position: relative;
`;

const VideoBackground = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  margin-top:25px;
`;

const HomePage = () => {
  return (
    <HomePageWrapper>
      <VideoBackground autoPlay loop muted>
        <source src={homeVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </VideoBackground>
    </HomePageWrapper>
  );
};

export default HomePage;
