import React from 'react';
import styled from 'styled-components';
import careersImage from './images/careers.png';

const CareersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TeamImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
`;

const TextWrapper = styled.div`
  width: 50%;
  padding-left: 20px;
  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    padding-top: 20px;
  }
`;

const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Text = styled.p`
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.5;
`;

const Careers = () => {
  return (
    <CareersWrapper>
      <TextWrapper>
        <Title>Careers</Title>
        <Text>
        We are a newly established company, embarking on an exciting journey to make a difference in our industry. 
        At the core of our values is a commitment to excellence, innovation, and sustainability. 
        We believe that by combining these values with the right team, we can achieve great things.
        </Text>
        <Text>
        As we begin this adventure, we are looking for talented individuals who share our vision and passion for creating a better future. 
        We are seeking driven and creative individuals who thrive in a collaborative environment and are committed to delivering results.
        </Text>
        <Text>
        We believe that our team is our greatest asset, and we are committed to providing an inclusive and supportive workplace culture that fosters growth, development, and success.
        We offer competitive compensation, flexible work arrangements, and a range of benefits and perks to help our team members thrive.
        </Text>
        <Text>
        If you are interested in joining us on this exciting journey, we invite you to explore our current job openings and submit your application.
        We look forward to hearing from you!
        </Text>
      </TextWrapper>
      <ImageWrapper>
        <TeamImage src={careersImage} alt="Team" />
      </ImageWrapper>
    </CareersWrapper>
  );
};

export default Careers;
